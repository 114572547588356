import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { serialize } from 'object-to-formdata';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helper/AxiosInstance';
import useForm from 'src/hooks/useForm';
import Modal from '../shared/Modal';
import Form from './Form';

export interface ItemsForm {
  name: string | undefined;
  alt_name: string | undefined;
  price: string | undefined;
  messages_count: string | undefined;
}

export default function AddFormBody({ reFetching }: { reFetching: any }) {
  const { t } = useTranslation();
  const initialValues = {
    name: undefined,
    alt_name: undefined,
    price: undefined,
    messages_count: undefined
  } satisfies ItemsForm;
  const [errors, setErrors] = useState<ItemsForm | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const submitHandler = useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post(
        'sms_packages/add',
        serialize(values, { nullsAsUndefineds: true, indices: true })
      );
      await reFetching();
      helper.resetForm();
      setVisible(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { values, handleChange, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  return (
    <>
      <button
        className="btn-with-icon !text-blue-600 !p-0"
        type="button"
        onClick={() => setVisible(true)}
      >
        <span>{t('add-new')}</span>
      </button>
      <Modal
        title={t('add-new')}
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <Form
          errors={errors}
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isLoading={disabled}
        />
      </Modal>
    </>
  );
}


