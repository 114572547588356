import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

const Form = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  isLoading
}: {
  handleSubmit: any;
  handleChange: any;
  values: any;
  errors: any;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">{t('name')}</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={values.name}
            name="name"
            onChange={handleChange}
          />
          {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('alt-name')}</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            name="alt_name"
            value={values.alt_name}
            onChange={handleChange}
          />
          {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('price')}</label>
          <input
            type="number"
            autoComplete="off"
            value={values.price}
            name="price"
            onChange={handleChange}
            min={0}
            step="any"
            className="form-input form-outline"
          />
          {errors?.price ? <span className="form-error">{errors?.price}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('messages-count')}</label>
          <input
            type="number"
            autoComplete="off"
            value={values.messages_count}
            name="messages_count"
            min={0}
            onChange={handleChange}
            className="form-input form-outline"
          />
          {errors?.messages_count ? (
            <span className="form-error">{errors?.messages_count}</span>
          ) : null}
        </div>
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
      </div>
    </form>
  );
};

export default Form;


