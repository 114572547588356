import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import AddFormBody from 'src/components/coupons/AddForm';
import UpdateForm from 'src/components/coupons/UpdateForm';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';

const Coupons: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const [params, setParams] = useSearchParams({
    page: '1'
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['coupons'],
    queryFn: () => axiosInstance.get('coupons', { params: params }).then((res) => res.data)
  });

  return (
    <div className="p-6 space-y-4 grid">
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('name')}</th>
              <th>{t('code')}</th>
              <th>{t('discount')}</th>
              <th>{t('max-discount')}</th>
              <th>{t('min-amount')}</th>
              <th>{t('coupon-limit')}</th>
              <th>{t('user-limit')}</th>
              <th>{t('owner-percentage')}</th>
              <th>{t('start-date')}</th>
              <th>{t('end-date')}</th>
              <th>{t('status')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {data?.result?.coupons?.data?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.name} - {item.alt_name}
                    </td>

                    <td>{item.code}</td>

                    <td>
                      {item.discount_type === 'percentage'
                        ? item.discount_value + '%'
                        : CurrencyFormatter(item.discount_value || 0)}
                    </td>

                    <td>{item.max_discount || 0}</td>

                    <td>{item.min_amount || 0}</td>

                    <td>{item.coupon_limit || 0}</td>
                    <td>{item.user_limit || 0}</td>
                    <td>{item.owner_per || '-'}</td>

                    <td>
                      <SharedTime date={item.start_date} />
                    </td>

                    <td>
                      <SharedTime date={item.end_date} />
                    </td>

                    <td>
                      <ChangeStatus
                        active={!!item.is_active}
                        refetch={refetch}
                        pathname={'coupons/update_is_active/' + item.id}
                      />
                    </td>

                    <td>
                      <div className="inline-flex gap-2">
                        <UpdateForm
                          reFetching={refetch}
                          scheme={item}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => <AddFormBody reFetching={refetch} />}
        isEmpty={!data?.result?.coupons?.data?.length}
        pagination={data?.result?.coupons?.pagination}
        searchProps={{
          onChange: (e) => {
            setParams((param) => {
              param.set('search_key', (e.target as any).value);

              param.set('page', '1');
              return param;
            });
          }
        }}
        onNextClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (data?.result?.coupons?.pagination.current_page >= 1
                ? data?.result?.coupons?.pagination.current_page + 1
                : 1
              ).toString()
            );

            return param;
          });
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (data?.result?.coupons?.pagination.current_page >= 1
                ? data?.result?.coupons?.pagination.current_page - 1
                : 1
              ).toString()
            );

            return param;
          });
        }}
        loading={isLoading}
      />
    </div>
  );
};

export default Coupons;






