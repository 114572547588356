import { AxiosError } from 'axios';
import { serialize } from 'object-to-formdata';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helper/AxiosInstance';
import useForm from 'src/hooks/useForm';
import Modal from '../shared/Modal';
import { ItemsForm } from './AddForm';
import Form from './Form';

export default function UpdateForm({ reFetching, scheme }: { reFetching: any; scheme: any }) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<ItemsForm | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const submitHandler = useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const fd = serialize(values, { nullsAsUndefineds: true, indices: true });

      if (!(values.image instanceof File)) fd.delete('image');

      await axiosInstance.post('sms_packages/update', fd);
      await reFetching();
      helper.resetForm();
      setVisible(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { values, handleChange, handleSubmit, setFieldValue }
  } = useForm({ initialValues: scheme, submitHandler });

  return (
    <>
      <button
        className="edit-btn max-w-fit"
        type="button"
        onClick={() => setVisible(true)}
      >
        <span>{t('edit')}</span>
      </button>
      <Modal
        title={`${scheme.name} - ${scheme.alt_name}`}
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <Form
          errors={errors}
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isLoading={disabled}
        />
      </Modal>
    </>
  );
}

