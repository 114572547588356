import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoadingComponent from './components/shared/LoadingComponent';
import { authenticationRoutes, routes } from './helper/routes';
import ScrollToTop from './hooks/ScrollToTop';
import useAuth from './hooks/useAuth';
import './i18n';
import HeaderLayout from './layouts/HeaderLayout';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { RootState } from './store';
import AuthLayout from './layouts/AuthLayout';

function AppWrapper() {
  const isLoading = useAuth();
  const { token } = useSelector((state: RootState) => state.auth);
  const { i18n } = useTranslation();

  // const { filterRoutes } = usePermissions();

  useEffect(() => {
    document.querySelector('html')?.setAttribute('dir', i18n.language === 'ar' ? 'rtl' : 'ltr');
    document.querySelector('html')?.setAttribute('lang', i18n.language);
  }, [i18n]);

  if (isLoading) return <LoadingComponent />;

  return (
    <Fragment>
      {token ? (
        <HeaderLayout>
          <Routes>
            {routes.map((route: any, index: string | number) => (
              <Route
                key={index}
                element={route.element}
                path={route.path}
              ></Route>
            ))}
          </Routes>
        </HeaderLayout>
      ) : (
        <AuthLayout>
          <Routes>
            {authenticationRoutes.map((route: any, index: string | number) => (
              <Route
                key={index}
                {...route}
              ></Route>
            ))}
          </Routes>
        </AuthLayout>
      )}
    </Fragment>
  );
}

function App() {
  return (
    <AnimatePresence>
      <Router>
        <AppWrapper />
        <ScrollToTop />
      </Router>
    </AnimatePresence>
  );
}

export default App;

