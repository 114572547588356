import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import Select from '../shared/Select';

const Form = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  isLoading,
  setFieldValue
}: {
  handleSubmit: any;
  handleChange: any;
  values: any;
  errors: any;
  isLoading: boolean;
  setFieldValue: any;
}) => {
  const { t } = useTranslation();

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">{t('name')}</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={values.name}
            name="name"
            onChange={handleChange}
          />
          {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('alt-name')}</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            name="alt_name"
            value={values.alt_name}
            onChange={handleChange}
          />
          {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('code')}</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            name="code"
            value={values.code}
            onChange={handleChange}
          />
          {errors?.code ? <span className="form-error">{errors?.code}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('discount-type')}</label>
          <Select
            options={[
              { key: 'fixed', name: t('fixed') },
              { key: 'percentage', name: t('percentage') }
            ]}
            value={values.discount_type}
            onSelect={function (value: any): any {
              return setFieldValue('discount_type', value);
            }}
            optionTxt={'name'}
            optionValue={'key'}
          />

          {errors?.discount_type ? (
            <span className="form-error">{errors?.discount_type}</span>
          ) : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('discount-value')}</label>
          <input
            type="number"
            autoComplete="off"
            value={values.discount_value}
            name="discount_value"
            min={0}
            onChange={handleChange}
            className="form-input form-outline"
          />
          {errors?.discount_value ? (
            <span className="form-error">{errors?.discount_value}</span>
          ) : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('max-discount')}</label>
          <input
            type="number"
            autoComplete="off"
            value={values.max_discount}
            name="max_discount"
            min={0}
            onChange={handleChange}
            className="form-input form-outline"
          />
          {errors?.max_discount ? <span className="form-error">{errors?.max_discount}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('min-amount')}</label>
          <input
            type="number"
            autoComplete="off"
            value={values.min_amount}
            name="min_amount"
            min={0}
            onChange={handleChange}
            className="form-input form-outline"
          />
          {errors?.min_amount ? <span className="form-error">{errors?.min_amount}</span> : null}
        </div>

        <div className="form-group">
          <p className="form-label">{t('start-date')}</p>
          <input
            type="date"
            name="start_date"
            value={values.start_date}
            onChange={handleChange}
            className="form-input form-outline"
          />
          {errors?.start_date ? <span className="form-error">{errors?.start_date}</span> : null}
        </div>

        <div className="form-group">
          <p className="form-label">{t('end-date')}</p>
          <input
            type="date"
            name="end_date"
            value={values.end_date}
            onChange={handleChange}
            className="form-input form-outline"
          />
          {errors?.end_date ? <span className="form-error">{errors?.end_date}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('owner-percentage')}</label>
          <input
            type="text"
            autoComplete="off"
            value={values.owner_per}
            name="owner_per"
            min={0}
            onChange={handleChange}
            className="form-input form-outline"
          />
          {errors?.owner_per ? <span className="form-error">{errors?.owner_per}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('coupon-limit')}</label>
          <input
            type="number"
            autoComplete="off"
            value={values.coupon_limit}
            name="coupon_limit"
            min={0}
            onChange={handleChange}
            className="form-input form-outline"
          />
          {errors?.coupon_limit ? <span className="form-error">{errors?.coupon_limit}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">{t('user-limit')}</label>
          <input
            type="number"
            autoComplete="off"
            value={values.user_limit}
            name="user_limit"
            min={0}
            onChange={handleChange}
            className="form-input form-outline"
          />
          {errors?.user_limit ? <span className="form-error">{errors?.user_limit}</span> : null}
        </div>
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
      </div>
    </form>
  );
};

export default Form;



