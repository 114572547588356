import { Icon } from '@iconify/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveUserInfo, storeToken } from 'src/reducers/auth';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
interface AuthInterface {
  email_mobile?: string;
  password?: string;
}

const Login: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    email_mobile: undefined,
    password: undefined
  } satisfies AuthInterface;

  const [errors, setErrors] = useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'login',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(error);
          const token = data.result.access_token;
          const user = data.result.profile;
          localStorage.setItem('@token', token);

          dispatch(storeToken(token));
          dispatch(saveUserInfo(user));
          navigate(data.result.go_settings ? '/settings' : '/', { replace: true });
          generateAlert(data.message, 'success');
          formikHelpers.resetForm();
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <form
      className="space-y-3"
      onSubmit={handleSubmit}
    >
      <p className="text-2xl text-black font-semibold text-center mb-8">{t('sign-in')}</p>
      <div className="form-group">
        <label
          htmlFor="email"
          className="form-label"
        >
          {t('email')}
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          className="form-input form-outline !bg-gray-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('email_mobile', target.value)
          }
        />
        {errors?.email_mobile ? <span className="form-error">{errors?.email_mobile}</span> : null}
      </div>
      <div className="form-group">
        <label
          htmlFor="password"
          className="form-label"
        >
          {t('password')}
        </label>
        <input
          type="password"
          name="password"
          id="password"
          placeholder="••••••"
          className="form-input form-outline !bg-gray-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('password', target.value)
          }
        />
        {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
      </div>
      <div className="!mt-8 space-y-2">
        <button
          className="btn-with-icon bg-primary w-full !p-4"
          type="submit"
          disabled={disabled}
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('sign-in')}</span>
          )}
        </button>
      </div>
    </form>
  );
};

export default Login;


