import { ReactNode } from 'react';
import Card from 'src/components/shared/Card';
import { titles } from '../helper/PageTitles';
import { useTranslation } from 'react-i18next';
import { ChangeLanguage } from './HeaderLayout';
import { Link } from 'react-router-dom';
import Image from 'src/components/shared/Image';


export default function AuthLayout({ children }: { children?: ReactNode }) {
  const { t } = useTranslation();

  return (
    <section>
      <header className="flex items-center justify-between h-20 gap-4 px-6 bg-white sticky top-0 z-10 border-b border-b-gray-100">
        <div className="lg:w-[300px]">
          <Link
            to="https://sa.salon"
            className="shrink-0"
            title="sa salon - صالونات السعودية"
          >
            <Image
              src="/logo.png"
              alt="website logo"
              width="250"
              height="50"
              className="w-full max-w-[9rem] object-contain shrink-0 object-center"
            />
          </Link>
        </div>

        <ChangeLanguage />
      </header>

      <div className="min-h-[calc(100vh-5rem)] py-8 px-6 flex justify-center items-center bg-gray-50">
        <Card className="max-w-xl mx-auto space-y-4 !p-8 !border-none">{children}</Card>
      </div>
    </section>
  );
}







