import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Image from 'src/components/shared/Image';
import useLogout from 'src/hooks/useLogout';
import { RootState } from 'src/store';
import { MoveToBottom, MoveToTop } from '../animations';

interface RouterInterface {
  title: string;
  path: string;
  icon: string;
}

const routes: RouterInterface[] = [
  {
    title: 'dashboard',
    icon: 'ri:home-5-line',
    path: '/'
  },

  {
    title: 'centers',
    icon: 'solar:buildings-linear',
    path: '/centers'
  },

  {
    title: 'plans',
    icon: 'solar:box-outline',
    path: '/packages'
  },
  {
    title: 'sms-packages',
    icon: 'arcticons:sms-import-export',
    path: '/sms-packages'
  },
  {
    title: 'coupons',
    icon: 'solar:ticket-sale-line-duotone',
    path: '/coupons'
  },
  {
    title: 'subscriptions',
    icon: 'solar:users-group-two-rounded-line-duotone',
    path: '/subscriptions'
  },
  {
    title: 'tutorials',
    icon: 'solar:documents-minimalistic-linear',
    path: '/tutorials'
  },
  {
    title: 'tutorial-categories',
    icon: 'solar:checklist-linear',
    path: '/tutorial-categories'
  }
];

const SideBar: FC = () => {
  const location = useLocation();
  const menuContainer = useRef<HTMLElement | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { handleLogged } = useLogout();
  const { t } = useTranslation();

  const openCloseMenu = useCallback(function (ev: React.MouseEvent) {
    const currentTarget = ev.target as HTMLElement | null;
    if (currentTarget === menuContainer?.current) {
      menuContainer?.current?.classList.add('hidden');
    }
  }, []);

  const closeMenu = useCallback(function () {
    menuContainer?.current?.classList.add('hidden');
  }, []);

  return (
    <aside
      className="max-w-fit xl:max-w-[18rem] shrink-0 bg-gray-800/20 shadow-2xl shadow-gray-600/5 z-20 flex-1"
      ref={menuContainer}
      data-type="menu"
      onClick={openCloseMenu}
    >
      <div className="w-full max-w-[18rem] py-0 px-4 bg-white xl:bg-gray-50 sticky top-0 min-h-screen max-h-screen overflow-y-auto flex flex-col border-r rtl:border-r-none rtl:border-l border-gray-200 divide-y divide-gray-200">
        <div className="w-full hidden xl:block py-1.5">
          <Link
            to="/"
            className="shrink-0"
          >
            <img
              src="/logo.png"
              alt="website logo"
              width="250"
              height="50"
              className="w-full max-w-[9rem] object-contain mx-auto object-center"
            />
          </Link>
        </div>

        <nav className="flex-1 py-4">
          <ul className="space-y-1">
            {routes.map((route: RouterInterface, index: string | number) => (
              <motion.li
                key={index}
                animate="visible"
                initial="hidden"
                variants={MoveToTop}
              >
                <Link
                  to={route.path}
                  className={[
                    'w-full flex items-center gap-3 text-base py-2 px-3 rounded transition-all group',

                    route.path == '/' && location.pathname == route.path
                      ? 'bg-primary/10 text-primary font-semibold'
                      : new RegExp(route.path, 'gi').test(location.pathname) && route.path !== '/'
                      ? 'bg-primary/10 text-primary font-semibold'
                      : 'text-gray-500'
                  ].join(' ')}
                >
                  <span className="shrink-0 group-hover:text-black">
                    <Icon
                      icon={route.icon}
                      width={22}
                      height={22}
                    />
                  </span>
                  <span className="hidden xl:inline">{t(route.title)}</span>
                </Link>
              </motion.li>
            ))}
          </ul>
        </nav>
        <div className="w-full flex items-center justify-center xl:justify-between py-4">
          <Link
            to="/account/profile"
            className="shrink-0 hidden xl:flex gap-4"
          >
            <Image
              src={user?.image}
              className="shrink-0 w-10 h-10 rounded-full"
            />
            <div className="flex-1 grid">
              <motion.p
                animate="visible"
                initial="hidden"
                variants={MoveToTop}
                className="text-base font-bold text-gray-800 tabular-nums"
              >
                {user?.name || 'UNKNOWN'}
              </motion.p>
              <motion.a
                animate="visible"
                initial="hidden"
                variants={MoveToBottom}
                className="text-sm font-medium text-gray-500 line-clamp-1"
              >
                {user?.email || 'UNKNOWN'}
              </motion.a>
            </div>
          </Link>
          <button
            className="shrink-0  text-gray-500 flex items-center justify-center"
            onClick={handleLogged}
          >
            <Icon
              icon="heroicons-outline:logout"
              width={24}
            />
          </button>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;

